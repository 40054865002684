import React from "react"
import { MT5DesktopQuery } from "../../prismic/staticQueries"
import { connect, useSelector } from "react-redux"

import Layout from "../../components/layout"
import {
  HeadingBanner,
  SubscriptionPanel,
  QuickStats,
  PrismicRichText,
} from "../../components/shared"

const OurMission = () => {
  const language = useSelector(state => state.language)
  const {
    banner_image,
    page_heading,
    main_buttons,
    content_description,
    content_heading,
    download_label,
    download_link,
    stats_heading,
    stats_items,
    content_body,
  } = MT5DesktopQuery(language)
  return (
    <>
      <Layout>
        <HeadingBanner
          heading={page_heading}
          banner_image={banner_image}
          buttons={main_buttons}
        />
        <QuickStats heading={stats_heading} items={stats_items} />

        <section className="job-contents p-top-20 p-bottom-65">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3>{content_heading[0].text}</h3>
                <p>{content_description[0].text}</p>
                <p className="text-center">
                  <a
                    href={download_link[0].text}
                    style={{ backgroundColor: "#1175bb", color: "#fff" }}
                    className="btn  rounded-0 btn-icon icon-right"
                  >
                    {download_label[0].text}
                    <span className="la la-download"></span>
                  </a>
                </p>
                <PrismicRichText data={content_body} />
              </div>
            </div>
          </div>
        </section>

        <SubscriptionPanel />
      </Layout>
    </>
  )
}

const mapStateToProps = state => {
  return {
    language: state.language,
  }
}

export default connect(mapStateToProps)(OurMission)
